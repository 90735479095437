<template>
  <PageDefault :isLoading="isLoading">
    <template v-slot:content>
      <div class="px-2">
        <div class="my-3">
          <p v-translate class="is-size-4">
            Ha expirado el tiempo para realizar la compra
          </p>
        </div>
        <translate>
          Tu reserva se ha liberado, pero puedes volver a iniciar tu compra, si así lo
          deseas.
        </translate>
        <div>
          <button
            class="button is-info mt-4"
            data-cy="link-new-order"
            @click="newMultipassOrder"
          >
            <translate>Nuevo pedido</translate>
          </button>
        </div>
      </div>
    </template>
  </PageDefault>
</template>

<script>
import Vue from "vue"
import Component from "vue-class-component"

@Component
export default class MultipassOrderExpired extends Vue {
  isLoading = false
  order = null

  mounted() {
    this.getOrder()
  }

  newMultipassOrder() {
    this.$router.push({
      name: "multipass-detail",
      params: { lookupRef: this.order.multipass_config.lookup_ref },
      query: this.$defaultQuery(),
    })
  }

  getOrder() {
    this.order = null
    this.isLoading = true
    this.$api.getMultipassOrder(this.$route.params.lookup_ref).then((response) => {
      this.order = response
      this.isLoading = false
    })
  }
}
</script>
